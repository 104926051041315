import Separator from 'components/dist/atoms/Separator';
import Stack from 'components/dist/atoms/Stack';
import Text from 'components/dist/atoms/Text';
import Tooltip from 'components/dist/atoms/Tooltip';
import ScreenSizeTooltip from 'components/dist/molecules/ScreenSizeTooltip';
import UserNameText from 'components/dist/molecules/UserNameText';
import { LoanRoleDto } from "src/backend";
import { RoleType } from "src/constants/loan";
import { useUser } from 'src/hooks/use-user';
import { LoanRole } from "src/types/loan";
import { getInitials } from "src/utils/get-initials";
import { getUserDisplayName } from "src/utils/user/get-user-display-name";
import { isRoleABorrower } from 'src/utils/user/is-role-a-borrower';

import { UserAvatar } from "../user/user-avatar";

const MAX_AVATARS = 3

interface RolesAvatarsProps {
    roles: LoanRoleDto[]
}

export const RolesAvatars = (props: RolesAvatarsProps) => {
    const total = props.roles.length;
    const { isLender, user } = useUser();
    const borrowers = props.roles.filter(role => isRoleABorrower(role.role));
    const lenders = props.roles.filter(role => !isRoleABorrower(role.role));

    const myTeam = isLender ? lenders : borrowers;
    const otherTeam = isLender ? borrowers : lenders;

    return <div onClick={(event) => event.preventDefault()}>
        <Tooltip.Provider>
            <ScreenSizeTooltip>
                <ScreenSizeTooltip.Trigger className="-space-x-1 flex overflow-hidden py-1">
                    {[...props.roles].sort(sortByRole)
                        .filter((_, index) => index < MAX_AVATARS)
                        .map((role) =>
                            <UserAvatar
                                key={role.user.id}
                                className='ring-1 ring-white'
                                avatarDocId={role.user.avatarDocId}
                                size="xsm"
                                role={role.role}
                                userId={role.user.id} >
                                {getInitials(getUserDisplayName(role.user))}
                            </UserAvatar>
                        )}
                    {total > MAX_AVATARS && <UserAvatar
                        className='ring-1 ring-black-primary text-black-primary'
                        size="xsm"
                        role={null}
                        userId={null} >
                        +{total - MAX_AVATARS}
                    </UserAvatar>}
                </ScreenSizeTooltip.Trigger>
                <ScreenSizeTooltip.Content
                    align="start"
                    side="bottom"
                    className='text-xs max-w-44 sm:max-w-sm sm:max-w-auto sm:min-w-44 p-4 lg:p-2'
                    alignOffset={0}>
                    <Stack space="sm">
                        {myTeam.length > 0 &&
                            <Stack space="sm">
                                <Text size="inherit" weight="medium">
                                    My Team
                                </Text>
                                {myTeam.map(role => <UserNameText
                                    isMe={role.user.id === user.id}
                                    key={role.id}
                                    // @ts-expect-error
                                    size="inherit"
                                    user={role.user} />)}
                            </Stack>
                        }
                        {myTeam.length > 0 && otherTeam.length > 0 && <Separator />}
                        {otherTeam.length > 0 && <Stack space="sm">
                            <Text size="inherit" weight="medium">
                                {isLender ? "Borrowing" : "Lending"} Team
                            </Text>
                            {otherTeam.map(role => <UserNameText
                                isMe={role.user.id === user.id}
                                key={role.id}
                                // @ts-expect-error
                                size="inherit"
                                user={role.user} />)}
                        </Stack>
                        }
                    </Stack>
                </ScreenSizeTooltip.Content>
            </ScreenSizeTooltip>
        </Tooltip.Provider>
    </div>
}

const sortByRole = (a: LoanRole, b: LoanRole) => {
    // sort by role LeadLender first then Lender then LeadBorrower then Borrower
    if (a.role === RoleType.LeadLender && b.role !== RoleType.LeadLender) {
        return -1;
    }
    if (a.role === RoleType.LENDER && b.role === RoleType.LeadBorrower) {
        return -1;
    }
    if (a.role === RoleType.LENDER && b.role === RoleType.BORROWER) {
        return -1;
    }
    if (a.role === RoleType.LeadBorrower && b.role === RoleType.BORROWER) {
        return -1;
    }
    return 1;

}
